import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { StandardMilestoneDetails } from "./Airline.interface";
import { lupaService } from "../../../services/lupa";

interface RowData {
  id: number;
  dataSource: string;
  airlineCode: string | undefined | null;
  milestoneName: string;
  isStandard: boolean;
  standardMilestoneId: number | null;
  isRemark: boolean;
  type: "DELETED" | "ADDED" | "UPDATED" | "NONE";
}

interface TableRowsProps {
  rowsData: RowData[];
  deleteTableRows: (index: number, dataSource: string) => void;
  handleChange: (
    index: number,
    event: string | number | boolean,
    type: string,
    dataSource: string
  ) => void;
}

const TableRows: React.FC<TableRowsProps> = ({
  rowsData,
  deleteTableRows,
  handleChange,
}) => {
  const [airlineStandardMilestoneData, setAirlineStandardMilestoneData] =
    useState<StandardMilestoneDetails[]>([]);

  const fetchStandardMilestone = () => {
    lupaService.getStandardMilestones()?.then((res: any) => {
      setAirlineStandardMilestoneData(res.data.standardMilestones);
    });
  };

  useEffect(() => {
    fetchStandardMilestone();
  }, []);

  return (
    <tbody>
      {rowsData.map((data, index) => {
        const { milestoneName, isStandard, standardMilestoneId,isRemark } = data;
        if (data.type === "DELETED") return null;
        return (
            <tr key={data.id}>
              <td className="text-center">
                <Form.Control
                    type="text"
                    aria-describedby="milestoneNameHelpBlock"
                    onChange={(event) =>
                        handleChange(
                            index,
                            event.target.value,
                            "milestoneName",
                            data.dataSource
                        )
                    }
                    value={milestoneName}
                />
              </td>

              <td className="text-center" align="center" valign="middle">
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={isStandard}
                    onChange={(event) =>
                        handleChange(
                            index,
                            event.target.checked,
                            "isStandard",
                            data.dataSource
                        )
                    }
                />
              </td>

              <td className="text-center">
                <Form.Select
                    aria-label="select standard milestone"
                    onChange={(event) =>
                        handleChange(
                            index,
                            +event.target.value,
                            "standardMilestoneId",
                            data.dataSource
                        )
                    }
                    value={standardMilestoneId ?? ""}
                >
                  <option value="">Select Milestone</option>
                  {airlineStandardMilestoneData.map((standardMilestoneData) => (
                      <option
                          key={standardMilestoneData.id}
                          value={standardMilestoneData.id}
                      >
                        {standardMilestoneData.name}
                      </option>
                  ))}
                </Form.Select>
              </td>
              <td className="text-center">
                <Form.Check
                    type="switch"
                    id="custom-switch-1"
                    checked={isRemark}
                    onChange={(event) =>
                        handleChange(
                            index,
                            event.target.checked,
                            "isRemark",
                            data.dataSource
                        )
                    }
                />

              </td>
              <td align="center" valign="middle">
                <Button
                    className="btn btn-danger"
                    variant="danger"
                    size="sm"
                    onClick={() => deleteTableRows(index, data.dataSource)}
                >
                  <i className="bi bi-trash"></i>
                </Button>
              </td>

            </tr>
        );
      })}
    </tbody>
  );
};

export default TableRows;

import React, { FC } from "react";
import { FormattedData } from "../../../models/FormattedData";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";

interface FormattedDataDisplayProps {
  data: FormattedData | null;
}

export const FormattedDataDisplay: FC<FormattedDataDisplayProps> = ({
  data,
}) => {
  const detailsBlock = (
    <Container className="mx-0">
      <Row>
        {/* First Column: AWB, Flight, Last Activity */}
        <Col sm={4}>
          <Col sm={12} className="mb-3">
            <h6>AWB</h6>
            <span>{data?.awbNumber}</span>
          </Col>
          <Col sm={12} className="mb-3">
            <h6>Flight</h6>
            <span>
              {data?.origin} - {data?.destination}
            </span>
          </Col>
          <Col sm={12} className="mb-3">
            <h6>Last Activity</h6>
            <Badge
              bg={
                data?.trackingStatus &&
                data?.trackingStatus.toLowerCase().includes("delivered")
                  ? "success"
                  : "secondary"
              }
              pill
            >
              {data?.trackingStatus}
            </Badge>
          </Col>
        </Col>

        {/* Second Column: Weight and Pieces */}
        <Col sm={4}>
          <Col sm={12} className="mb-3">
            <h6>Weight (Kg)</h6>
            <span>{data?.weight}</span>
          </Col>
          <Col sm={12} className="mb-3">
            <h6>No of Pieces</h6>
            <span>{data?.pieces}</span>
          </Col>
        </Col>

        {/* Third Column: Flight Details */}
        <Col sm={4}>
          <h6 className="fw-bold">Flight Details</h6>
          {data?.flights &&
            data?.flights.map((flight, index) => (
              <div key={index} className="border p-2 mb-2">
                <span style={{ fontWeight: 600 }}>
                  {flight.flightNumber} : {flight.origin} - {flight.destination}
                </span>
                <br />
                <span>ETD: {flight.etd}</span>
                <br />
                <span>ETA: {flight.eta}</span>
                <br />
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  );

  const historyBlock = (
    <Container className="mx-0">
      <Row className="col-12 ms-1 mx-1 my-1">
        <Col className="col-6"></Col>
        <Col className="col-6 pe-0">
          <Col className="col-4"></Col>
          <Col className="col-8 text-end text-muted ms-auto">
            <small>{data?.events?.length} Tracking Activity Found</small>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Container className="overflow-auto" style={{ height: 250 }}>
          <Table className="table table-fixed mt-2 border border-2">
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  Origin
                </th>
                <th scope="col" className="ps-1">
                  Destination
                </th>
                <th scope="col" className="ps-1">
                  Milestone
                </th>
                <th scope="col" className="ps-1">
                  Pcs
                </th>
                <th scope="col" className="ps-1">
                  Flight
                </th>
                <th scope="col" className="ps-1">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.events?.map((h) => {
                return (
                  <tr>
                    <td className="ps-1">{h.origin}</td>
                    <td className="ps-1">{h.destination}</td>
                    <td className="ps-1">{h.milestone}</td>
                    <td className="ps-1">{h.pieces ? h.pieces : "-"}</td>
                    <td className="ps-1">{h.flightNumber}</td>
                    <td className="ps-1">{h.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Row>
      <Row className="col-12 ms-1 mx-1 my-1">
        <Col className="col-6"></Col>
        <Col className="col-6 pe-0">
          <Col className="col-4"></Col>
          <Col className="col-8 text-end text-muted ms-auto">
            <small>{data?.remarks?.length} Remarks Found</small>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Container className="overflow-auto">
          <Table className="table table-fixed mt-2 border border-2">
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  Category
                </th>
                <th scope="col" className="ps-1">
                  Message
                </th>
                <th scope="col" className="ps-1">
                  date
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.remarks?.map((remark) => {
                return (
                  <tr>
                    <td className="ps-1">{remark?.category}</td>
                    <td className="ps-1">{remark?.message}</td>
                    <td className="ps-1">{remark?.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Row>
    </Container>
  );

  const dialog = (
    <Container className="mx-0">
      {detailsBlock}
      {historyBlock}
    </Container>
  );

  return dialog;
};

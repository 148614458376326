import { useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import {
  ScrapingETDETADetail,
  ScrapingEventHistory,
  ScrapingFlightDetail,
  ScrapingTracking,
} from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
} from "material-react-table";
import { Col, Container, Form, Modal, Row, Button } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { Button } from "@mui/material";

const ScrapingTrackings = () => {
  const [awb, setAwb] = useState("");
  const [isValidAwb, setIsValidAwb] = useState(true);
  const [loading, setLoading] = useState(false);
  const [scrapingTracking, setScrapingTracking] = useState<ScrapingTracking[]>([]);
  const [showTable, setShowTable] = useState(false);

  const validateAwb = (awb: string) => /^[0-9]{3}-[0-9]{8}$/.test(awb);

  const submitForm = async () => {
    if (!validateAwb(awb)) {
      setIsValidAwb(false);
      return;
    }
    setIsValidAwb(true);
    setLoading(true);

    try {
      const data = await WebhookUiService.getScrapingTracking(awb);
      setScrapingTracking(data || []);
      setShowTable(true);
    } catch (error) {
      console.error("Error fetching AWB details:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<ScrapingTracking>[]>(() => [
    { header: "AWB Number", accessorKey: "awbNumber" },
    { header: "Tracking Status", accessorKey: "trackingStatus" },
    { header: "Origin", accessorKey: "origin" },
    { header: "Destination", accessorKey: "destination" },
    { header: "Weight", accessorKey: "weight" },
    { header: "Pieces", accessorKey: "pieces" },
    {
      header: "Flight Details",
      accessorFn: (row) => (
        <Button onClick={() => handleFlightClick(row.scrapingFlightDetail || [])}>
          View
        </Button>
      ),
    },
    {
      header: "Flight ETD ETA",
      accessorFn: (row) => (
        <Button onClick={() => handleEtdEtaClick(row.scrapingETDETADetail || [])}>
          View
        </Button>
      ),
    },
    {
      header: "Events",
      accessorFn: (row) => (
        <Button onClick={() => handleEventClick(row.scrapingEventHistories || [])}>
          View
        </Button>
      ),
    },
  ], []);

  const [selectedFlightDetails, setSelectedFlightDetails] = useState<
    ScrapingFlightDetail[]
  >([]);
  const [selectedEtdEtaDetails, setSelectedEtdEtaDetails] = useState<
    ScrapingETDETADetail[]
  >([]);
  const [selectedEventHistories, setSelectedEventHistories] = useState<
    ScrapingEventHistory[]
  >([]);

  const handleFlightClick = (flightDetails: ScrapingFlightDetail[]) => {
    setSelectedFlightDetails(flightDetails);
  };
  const handleEtdEtaClick = (Etdeta: ScrapingETDETADetail[]) => {
    console.log("button clicked");
    console.log(Etdeta);
    setSelectedEtdEtaDetails(Etdeta);
  };

  const handleEventClick = (eventHistories: ScrapingEventHistory[]) => {
    setSelectedEventHistories(eventHistories);
  };

  const FlightDetailsModal = ({
    flightDetails,
    onClose,
  }: {
    flightDetails: ScrapingFlightDetail[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Flight Number</th>
                <th>Flight Date</th>
                <th>Origin</th>
                <th>Destination</th>
              </tr>
            </thead>
            <tbody>
              {flightDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.flightNumber}</td>
                  <td>{detail.flightDate}</td>
                  <td>{detail.origin}</td>
                  <td>{detail.destination}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const FlightEtdetaModal = ({
    Etdeta,
    onClose,
  }: {
    Etdeta: ScrapingETDETADetail[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Flight Number</th>
                <th>Station</th>
                <th>Destination</th>
                <th>ETD </th>
                <th>ETA</th>
              </tr>
            </thead>
            <tbody>
              {Etdeta.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.flightNumber}</td>
                  <td>{detail.station}</td>
                  <td>{detail.destination}</td>
                  <td>{detail.etd}</td>
                  <td>{detail.eta}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const EventHistoriesModal = ({
    eventHistories,
    onClose,
  }: {
    eventHistories: ScrapingEventHistory[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Histories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Milestone</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Station</th>
                <th>Pieces</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {eventHistories.map((history, index) => (
                <tr key={index}>
                  <td>{history.milestone}</td>
                  <td>{history.origin}</td>
                  <td>{history.destination}</td>
                  <td>{history.station}</td>
                  <td>{history.pieces}</td>
                  <td>{history.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const table = useMaterialReactTable<ScrapingTracking>({
    columns,
    data: scrapingTracking,
    enableRowNumbers: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
    },
  });

  return (
    <>
      {selectedFlightDetails?.length > 0 && (
        <FlightDetailsModal
          flightDetails={selectedFlightDetails}
          onClose={() => setSelectedFlightDetails([])}
        />
      )}

      {selectedEtdEtaDetails?.length > 0 && (
        <FlightEtdetaModal
          Etdeta={selectedEtdEtaDetails}
          onClose={() => setSelectedEtdEtaDetails([])}
        />
      )}

      {selectedEventHistories?.length > 0 && (
        <EventHistoriesModal
          eventHistories={selectedEventHistories}
          onClose={() => setSelectedEventHistories([])}
        />
      )}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* <h1>Scraping Trackings</h1> */}
        <Container className="py-5">
          <h1>Scraping Trackings</h1>

          <Form>
            <Row className="my-2" style={{ alignItems: "flex-end" }}>
              <Col md={4}>
                <Form.Label htmlFor="inputBox">Enter AWB:</Form.Label>
                <Form.Control
                  type="text"
                  id="inputBox"
                  value={awb}
                  onChange={(e) => setAwb(e.target.value)}
                  isInvalid={!isValidAwb}
                />
                {!isValidAwb && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid AWB number (e.g., 176-12345678).
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col>
                <Button variant="primary" onClick={submitForm} disabled={loading}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
          {showTable && <MaterialReactTable table={table} />}
        </Container>
      </LocalizationProvider>
    </>
  );
};

export default ScrapingTrackings;

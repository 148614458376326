import { ReactNode, useState } from "react";
import ClientNavbar from "../../foundation/Navbar";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import { hardDeleteAndAddAWB } from "../../../services/awbDeleteAndAddScrap";
import { hardDeleteAndAddAWBBulk } from "../../../services/awbDeleteAndAddScrapBulk";
import { Link } from "react-router-dom";

interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}

const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <>
      {loading ? (
        <div className="position-relative">
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-white opacity-25"
            style={{ backdropFilter: "blur(10px)" }}
          />
          <Spinner
            animation="border"
            role="status"
            className="position-fixed top-50 start-50"
            style={{ zIndex: 9999 }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="spinner-overlay">{children}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const DeleteAdd = () => {
  const [Awb, setAwb] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidAwb, setIsValidAwb] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [result, setResult] = useState<any>(null);

  const submitForm = () => {
    setIsValidAwb(false);
    const awbRegex = /^\d{3}-\d{8}$/;

    if (!awbRegex.test(Awb)) {
      setIsValidAwb(false);
      return;
    }

    setIsValidAwb(true);
    setShowConfirmation(true);
  };

  const [selectedAwbs, setSelectedAwbs] = useState<any[]>([]);
  const [isBulkOperation, setIsBulkOperation] = useState(false);

  const LogsModal = ({
    logData,
    onClose,
  }: {
    logData: any[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logs for selected AWB details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>AWB Number</th>
                <th>Success</th>
                <th>Message</th>
                <th>API Status</th>
              </tr>
            </thead>
            <tbody>
              {logData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail?.trackingRecord.awbNumber}</td>
                  <td>{detail.isSuccess ? "Success" : "Fail"}</td>
                  <td>
                    {detail.message ===
                      "AWB deleted successfully and added to trackings."
                      ? "Done"
                      : detail.message}
                  </td>
                  <td>{detail?.statusCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const confirmDeleteAdd = async () => {
    try {
      let response;

      setShowConfirmation(false);
      setLoading(true);

      if (isBulkOperation) {
        response = await hardDeleteAndAddAWBBulk(result.awbNumbers);
      } else if (Awb !== "") {
        const singleResponse = await hardDeleteAndAddAWB(Awb);
        if (singleResponse.data.isSuccess) {
          toast.success(singleResponse.data.message);
        } else {
          toast.error(singleResponse.data.message);
        }
      }

      if (response) {
        setSelectedAwbs(response.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    } finally {
      setShowConfirmation(false);
      setLoading(false);
      setIsBulkOperation(false);
      setAwb("");
    }
  };

  return (
    <>
      {selectedAwbs.length > 0 && (
        <LogsModal logData={selectedAwbs} onClose={() => setSelectedAwbs([])} />
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* <ClientNavbar /> */}
        <Loader loading={loading}>
          <Container className="py-5">
            <h1>Delete and Add AWB (Scraping)</h1>
            <Form>
              <Row className="my-2" style={{ alignItems: "flex-end" }}>
                <Col md={4}>
                  <Form.Label htmlFor="inputBox">Enter AWB:</Form.Label>
                  <Form.Control
                    type="text"
                    id="inputBox"
                    name="inputBox"
                    onChange={(e) => setAwb(e.target.value)}
                    isInvalid={!isValidAwb}
                    disabled={isBulkOperation}
                  />
                  {!isValidAwb && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid AWB number (e.g., 176-12345678).
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={loading || isBulkOperation}
                  >
                    Submit
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="me-2"
                    as={Link as any}
                    to="/scrap-delete-add-log"
                  >
                    Logs
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>

        </Loader>
      </LocalizationProvider>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete and Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to proceed with delete and add operation?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDeleteAdd}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAdd;

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClientNavbar from '../../foundation/Navbar';
import DeleteAdd from './DeleteAndAddAWB';
import AWBDetailsView from './AWBDetails';
import ScrapingTrackings from './ScrapingTrackings';

const CombinePage: React.FC = () => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ClientNavbar />
                <hr />
                <DeleteAdd />
                <hr />
                <AWBDetailsView />
                <hr />
                <ScrapingTrackings />
                <hr />
            </LocalizationProvider>

        </div>
    );
};

export default CombinePage;
import axios from "axios";
import { apiEndpoints } from "./Utils";
import { GenericResponse } from "../models/Common";

export const hardDeleteCourierDetail = async (
  courierNumber: string
): Promise<GenericResponse<any>> => {
  try {
    return axios.delete(apiEndpoints.BASE_URL + "courier/" + courierNumber);
  } catch (error) {
    throw new Error("Failed to delete awb detail");
  }
};

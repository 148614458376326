import { Badge, Container, Modal } from "react-bootstrap";
import { Button as BootstrapButton } from "react-bootstrap";
import Base from "../../common/Base";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import { getAWBList, getCourierList } from "../../../services/customer";
import { useMemo, useState } from "react";
import { AWBList } from "../../../models/Common";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConfirmActionPopup from "../../modals/Confirm";
import { hardDeleteAWBDetail } from "../../../services/awbDetails";
import { toastTypes } from "../../../utils/toastTypes";
import WebhookUiService from "../../../services/webhook-ui";
import "../../../../src/popup.css";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { WebhookTrackingDetails } from "../tracking/WebhookTrackingDetails";
import { FormattedDataDisplay } from "../tracking/FormatteddataDisplay";
import { hardDeleteCourierDetail } from "../../../services/CourierDetails";
import { toast } from "react-toastify";
import { lupaService } from "../../../services/lupa";
import { SearchAwbFE } from "../tracking/SearchAwbFE";
import { AddCourier } from "./WebhookTestPage/AddCourier";

type ShowDelete = {
  show: boolean;
  awbNumber: string | undefined;
};


// Define the types for your data
interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface CourierTrack {
  trackingStatus: unknown;
  id: number;
  courierName: string | null;
  weight: string | null;
  trackingNumber: string;
  isActive: boolean;
  pieces: string | null;
  origin: string | null;
  destination: string | null;
  createdDateTime: string | null;
  etdEtaUpdate: boolean;
  manualwebhooksent: boolean;
  CourierTrackUserMapping: { user: User }[];
}

const defaultShowDelete = {
  show: false,
  awbNumber: undefined,
};

function CourierUsage() {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["customer"],
    () => getCourierList(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const customToast = (message: string) => {
    const splitMessage = message.split(".");
    const firstLine = splitMessage[0].trim();
    const secondLine = splitMessage[1] ? splitMessage[1].trim() : "";
    const thirdLine = splitMessage.slice(2).join(".").trim();
    toast.error(
      <div style={{ textAlign: "center" }}>
        <div>{firstLine}</div>
        <div>{secondLine}</div>
        <div> {thirdLine}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const addAwbToTracking = async (courierNumber: string) => {
    // toastTypes.info("Fetching AWB details", true);
    try {
      const { data } = await lupaService.addCourierToTrackingMicro({ courierNumber });

      toastTypes.success("Courier added to tracking successfully");
      // fetchData();

      return true;
    } catch (error: any) {
      if (
        error.response.data.message ===
        "Airline not yet live on Intemo platform"
      ) {
        customToast("Airline Not Live on.Intemo Platform");
      } else if (
        error.response.data.message ===
        "Access Denied. Tracking limit exceeded. Please contact admin."
      ) {
        customToast(
          "Access Denied. Tracking limit exceeded. Please contact admin."
        );
      } else if (
        error.response.data.message ===
        "AWB number must adhere to the format (e.g.,) 123-45678901. No Alphabets allowed."
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else if (
        error.response.data.message ===
        "Please enter a 11 digit AWB number (E.g.)123-45678901"
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else {
        toast.error(error.response.data.message);
      }

      console.log(error.response.data.message);
      return false;
    }
  };
  const [isRefetching, setIsRefetching] = useState(false);
  const [showDelete, setShowDelete] = useState<ShowDelete>(defaultShowDelete);
  const [rowdata, setData] = useState<{ data: CourierTrack[] }>({ data: [] });
  const navigate = useNavigate();
  const [checkboxState, setCheckboxState] = useState(false);

  // const handleClick = (id: number) => {
  //   navigate(`/billing/${id}`);
  // };

  // const handleExportRows = (rows: MRT_Row<CourierTrack>[]) => {
  //   const doc = new jsPDF();
  //   const tableData = rows.map((row) => {
  //     return Object.values({
  //       name: row.original.user
  //         .map((user) => user.firstName + " " + user.lastName)
  //         .join("\n"),
  //       email: row.original.user.map((user) => user.email).join("\n"),
  //       awbNumber: row.original.awbNumber,
  //       airlineCode: row.original.airlineCode,
  //       createdAt: new Date(row.original.createdDateTime).toLocaleDateString(),
  //     });
  //   });
  //   const tableHeaders = columns
  //     .map((c) => c.header)
  //     .slice(0, columns.length - 1);

  //   autoTable(doc, {
  //     head: [tableHeaders],
  //     body: tableData,
  //     margin: { top: 40 },
  //     columnStyles: {
  //       0: { cellWidth: 50 },
  //       1: { cellWidth: 60 },
  //       2: { cellWidth: 30 },
  //       3: { cellWidth: 15 },
  //       4: { cellWidth: 30 },
  //       // etc
  //     },
  //     didDrawPage: function (data) {
  //       // Header
  //       doc.setFontSize(26);
  //       doc.setTextColor(40);
  //       doc.text(`AWBs Count: ${rows.length}`, data.settings.margin.left, 22);

  //       // Footer
  //       var str =
  //         "Page " + (doc as any).internal.getCurrentPageInfo().pageNumber;
  //       console.log((doc as any).internal.getCurrentPageInfo());
  //       doc.setFontSize(10);

  //       // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  //       var pageSize = doc.internal.pageSize;
  //       var pageHeight = pageSize.height
  //         ? pageSize.height
  //         : pageSize.getHeight();
  //       doc.text(str, data.settings.margin.left, pageHeight - 10);
  //     },
  //   });
  //   doc.save("CourierTrack.pdf");
  // };
  const handleExportToExcel = (rows: MRT_Row<CourierTrack>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CourierTrack");

    XLSX.writeFile(workbook, "CourierTrack.xlsx");
  };

  const handleDeleteSubmit = (Courier: string) => {
    // const inputtedCourier = prompt(
    //   "Please enter the Courier Number number to confirm deletion"
    // );
    // if (inputtedCourier !== Courier) {
    //   toastTypes.error("Courier Number number does not match");
    //   return;
    // }
    hardDeleteCourierDetail(Courier)
      .then(async (data) => {
        console.log(data);
        if (data.data.isSuccess) {
          setIsRefetching(true);
          setShowDelete(defaultShowDelete);
          toastTypes.success("Courier Number deleted successfully");
          await refetch();
          setIsRefetching(false);
        } else {
          toastTypes.success(data.data.message);
          setShowDelete(defaultShowDelete);
        }
      })
      .catch((error) => {
        console.log(error);
        toastTypes.error(error.message);
      });
  };
  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  //webhook data ui exposure
  const [showDetails, setShowDetails] = useState(false);
  const [selectedAWBDetails, setSelectedAWBDetails] = useState<any[]>([]);
  const [selectedAWBNumber, setSelectedAWBNumber] = useState<string>("");

  const handleShowDetails = async (awbNumber: string) => {
    try {
      const response = await WebhookUiService.getAwbPopupData(awbNumber);
      console.log(response);

      if (!response) {
        throw new Error("No valid data received");
      }

      const formattedDetailsForTracking = [
        {
          trackingStatus: response.trackingStatus || "-",
          awbNumber: response.awbNumber || "-",
          origin: response.origin || "-",
          destination: response.destination || "-",
          milestone: response.milestone || "-",
          etd: response.etd || "-",
          eta: response.eta || "-",
          weight: response.weight || "-",
          pieces: response.pieces || "-",
          flights: response.flightsEtdEta || [],
          events: response.events || [],
          remarks: response.remarks || [],
        },
      ];
      // console.log("****************");
      // console.log(formattedDetailsForTracking);
      // console.log(response);
      // console.log("****************");
      setSelectedAWBDetails(formattedDetailsForTracking);
      setSelectedAWBNumber(awbNumber);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching details: ", error);
      setSelectedAWBDetails([]); // Set an empty array to handle changes
      setSelectedAWBNumber(""); // Reset selected AWB number
      setShowDetails(true);
    }
  };
  const webhookSentFilterFn: MRT_FilterFn<CourierTrack> = (row, id, filterValue) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const filterBoolean = filterValue === "true";
    return row.original.etdEtaUpdate === filterBoolean;
  };

  const manualwebhookSentFilterFn: MRT_FilterFn<CourierTrack> = (
    row,
    id,
    filterValue
  ) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const filterBoolean = filterValue === "true";
    return row.original.manualwebhooksent === filterBoolean;
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };
  const handleToggle = async (awbNumber: string, newValue: boolean) => {
    try {
      console.log(newValue);
      console.log(awbNumber);
      const inputtedAWBnumber = prompt(
        "Please enter the AWB number to confirm the update"
      );
      if (inputtedAWBnumber !== awbNumber) {
        toastTypes.error("AWB number does not match");
        setCheckboxState(false); // Uncheck the checkbox
        return;
      }

      const response = await WebhookUiService.updateEtdEta(awbNumber);
      console.log(response);
      if (response.data === "Success") {
        toastTypes.success("Update successful");
        // window.location.reload();
        refetch();
      } else {
        toastTypes.error("Conditions not met");
        newValue = false;
      }
    } catch (error) {
      console.error("Error updating toggle:", error);
      toastTypes.error("An error occurred while updating");
    }
  };
  const getBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "success";
      case "in transit":
        return "info";
      case "pending":
        return "warning";
      case "canceled":
        return "danger";
      default:
        return "secondary";
    }
  };

  const columns = useMemo<MRT_ColumnDef<CourierTrack>[]>(
    () => [
      // {
      //   header: "Email",
      //   accessorFn: (row) =>
      //     row.CourierTrackUserMapping.map((data) => data.user.email),
      //   filterVariant: "select",
      //   filterFn: "includesString",
      //   Cell: ({ cell }) =>
      //     cell.getValue<string[]>().map((email, index) => (
      //       <div key={index}>{email}</div>
      //     )),
      // },
      {
        header: "Email",
        accessorFn: (row) =>
          row.CourierTrackUserMapping.map((mapping) => mapping.user.email),
        filterVariant: "select",
        filterFn: "includesString",
        filterSelectOptions: (() => {
          const options = new Set<string>();

          // Safely handle data for filter options
          if (Array.isArray(data)) {
            data.forEach((row) =>
              row.CourierTrackUserMapping.forEach((mapping: { user: { email: string; }; }) =>
                options.add(mapping.user.email)
              )
            );
          }

          return Array.from(options).map((option) => ({
            text: option,
            value: option,
          }));
        })(),
        Cell: ({ cell }) =>
          cell.getValue<string[]>().map((email, index) => (
            <div key={index}>{email}</div>
          )),
      },

      {
        accessorKey: "trackingNumber",
        header: "AWB No.",
        Cell: ({ cell, row }) => (
          <Tooltip title="Click for more details" placement="top">
            <div
              className="awb-details-box"
              onClick={() => handleShowDetails(row.original.trackingNumber)}
            >
              <div>{row.original.trackingNumber}</div>
              <div className="info-circle">
                <InfoOutlinedIcon />
              </div>
            </div>
          </Tooltip>
        ),
      },
      {
        accessorKey: "origin",
        header: "Origin",
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? <Badge color="primary">{value}</Badge> : "-";
        },
      },
      {
        accessorKey: "destination",
        header: "Destination",
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? <Badge color="primary">{value}</Badge> : "-";
        },
      },
      {
        accessorKey: "pieces",
        header: "Pieces",
        Cell: ({ cell }) => {
          const value = cell.getValue<string | null>();
          return value ? <Badge color="secondary">{value}</Badge> : "-";
        },
      },
      {
        accessorKey: "weight",
        header: "Weight (kg)",
      },
      {
        accessorKey: "trackingStatus",
        header: "Tracking Status",
        accessorFn: (row) => row.trackingStatus,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return value ? <Badge bg={getBadgeColor(value)}>{value}</Badge> : "";
        },
      },
      {
        accessorKey: "createdAt",
        header: "Date of Entry",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = new Date(cell.getValue<string>());
          return dateValue ? dateValue.toLocaleDateString() : "-";
        },
      },
      {
        header: "Action",
        accessorFn: (row) => row.trackingNumber,
        enableColumnFilters: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              setShowDelete({ show: true, awbNumber: row.original.trackingNumber })
            }
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable<CourierTrack>({
    columns,
    data: data ?? [],
    enableRowSelection: true,
    // getRowId: (row) => row.id,
    initialState: { showColumnFilters: true, density: "compact" },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // rowCount: data?.data.length ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

  });

  return (
    <Base>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container fluid className="mt-3">
          <AddCourier addAwbToTracking={addAwbToTracking} />
          <MaterialReactTable table={table} />
          <Modal
            show={showDetails}
            onHide={handleCloseDetails}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Courier Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Array.isArray(selectedAWBDetails) &&
                selectedAWBDetails.length > 0 ? (
                selectedAWBDetails.map((awbDetail, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <FormattedDataDisplay data={awbDetail} />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "18px",
                  }}
                >
                  No data found
                </div>
              )}
            </Modal.Body>
          </Modal>
        </Container>
        {showDelete.show && (
          <ConfirmActionPopup
            show={showDelete.show}
            onHide={() => setShowDelete(defaultShowDelete)}
            onSubmit={() => handleDeleteSubmit(showDelete.awbNumber!)}
            trackingNumber={showDelete.awbNumber}
            header="Delete Courier Number"
            confirmText={
              `Are you sure you want to delete this <strong>${showDelete.awbNumber}</strong> Courier Number?`
            }
          />
        )}
      </LocalizationProvider>
    </Base>
  );
}

export default CourierUsage;

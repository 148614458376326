import { toast } from "react-toastify";
import { lupaService } from "../../../../services/lupa";
import { toastTypes } from "../../../../utils/toastTypes";
import { Container } from "react-bootstrap";
import Base from "../../../common/Base";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SearchAwbFE } from "../../tracking/SearchAwbFE";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCourier } from "./AddCourier";

function AddAWB() {
    const addAwbToTracking = async (awbNumber: string) => {
        try {
            const { data } = await lupaService.addAwbToTrackingMicro({ awbNumber });

            toastTypes.success("AWB added to tracking successfully");
            return true;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "An unexpected error occurred";

            if (errorMessage === "Airline not yet live on Intemo platform") {
                customToast("Airline Not Live on Intemo Platform.");
            } else if (errorMessage === "Access Denied. Tracking limit exceeded. Please contact admin.") {
                customToast("Access Denied. Tracking limit exceeded. Please contact admin.");
            } else if (
                errorMessage === "AWB number must adhere to the format (e.g.,) 123-45678901. No Alphabets allowed." ||
                errorMessage === "Please enter a 11 digit AWB number (E.g.)123-45678901"
            ) {
                customToast("Please enter an 11-digit AWB number. (E.g.)123-45678901.");
            } else {
                toast.error(errorMessage);
            }

            console.error(errorMessage);
            return false;
        }
    };
    const addCourierToTracking = async (courierNumber: string) => {
        // toastTypes.info("Fetching AWB details", true);
        try {
            const { data } = await lupaService.addCourierToTrackingMicro({ courierNumber });

            toastTypes.success("Courier added to tracking successfully");
            // fetchData();

            return true;
        } catch (error: any) {
            if (
                error.response.data.message ===
                "Airline not yet live on Intemo platform"
            ) {
                customToast("Airline Not Live on.Intemo Platform");
            } else if (
                error.response.data.message ===
                "Access Denied. Tracking limit exceeded. Please contact admin."
            ) {
                customToast(
                    "Access Denied. Tracking limit exceeded. Please contact admin."
                );
            } else if (
                error.response.data.message ===
                "AWB number must adhere to the format (e.g.,) 123-45678901. No Alphabets allowed."
            ) {
                customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
            } else if (
                error.response.data.message ===
                "Please enter a 11 digit AWB number (E.g.)123-45678901"
            ) {
                customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
            } else {
                toast.error(error.response.data.message);
            }

            console.log(error.response.data.message);
            return false;
        }
    };
    const customToast = (message: string) => {
        const splitMessage = message.split(".");
        const firstLine = splitMessage[0]?.trim();
        const secondLine = splitMessage[1]?.trim();
        const thirdLine = splitMessage.slice(2).join(".").trim();

        toast.error(
            <div style={{ textAlign: "center" }}>
                {firstLine && <div>{firstLine}</div>}
                {secondLine && <div>{secondLine}</div>}
                {thirdLine && <div>{thirdLine}</div>}
            </div>,
            { position: toast.POSITION.TOP_CENTER }
        );
    };

    return (
        <Base>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container fluid className="mt-3">
                    <SearchAwbFE addAwbToTracking={addAwbToTracking} />
                    <AddCourier addAwbToTracking={addCourierToTracking} />

                </Container>
            </LocalizationProvider>
        </Base>
    );
}

export default AddAWB;

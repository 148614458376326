import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { ScrapingHealth } from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import AWBUsage from "./AWBUsage";
import { NavLink } from "react-router-dom";

const ScrapingHealthCheck = () => {
  const {
    data: scrapingHealth,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("scrapingHealth", WebhookUiService.getscrapingHealth, {
    refetchOnWindowFocus: false,
  });
  // console.log(scrapingHealth);
  const [selectedServerId, setSelectedServerId] = useState("");

  const serverIdOptions = useMemo(() => {
    const uniqueIds = new Set(
      scrapingHealth?.map((data: { serverId: any }) => data.serverId)
    );
    return Array.from(uniqueIds);
  }, [scrapingHealth]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "Airline",
      desc: false,
    },
    // { id: "createdAt", desc: true },
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const statusFilterFn: MRT_FilterFn<ScrapingHealth> = (
    row,
    id,
    filterValue
  ) => {
    if (filterValue === "") {
      return true;
    }

    const lowercaseValue = filterValue.toLowerCase();
    return row.original.status.toLowerCase().startsWith(lowercaseValue);
  };

  const filteredData = useMemo(() => {
    return selectedServerId
      ? scrapingHealth?.filter(
        (data: { serverId: string }) => data.serverId === selectedServerId
      )
      : scrapingHealth ?? [];
  }, [scrapingHealth, selectedServerId]);

  const columns = useMemo<MRT_ColumnDef<ScrapingHealth>[]>(
    () => [
      {
        header: "Airline",
        filterFn: "includesString",
        accessorFn: (row) => row?.airlineName || "",
        sortDescFirst: false,
      },
      {
        header: "Status",
        accessorFn: (row) =>
          row.status === "Pass" ? (
            <i className="bi bi-check-circle-fill text-success"></i>
          ) : (
            <i className="bi bi-x-circle-fill text-danger"></i>
          ),

        filterFn: statusFilterFn,
      },
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",
      },
      {
        header: "Server Name",
        filterFn: "includesString",
        accessorFn: (row) => row?.serverId || "",
      },
      {
        header: "Retry count",
        filterFn: "includesString",
        accessorFn: (row) =>
          row.retryCount === 0 ? "-" : row.retryCount || "",
      },
      {
        header: "Airline Code",
        filterFn: "includesString",
        accessorFn: (row) => row?.airlineCode || "",
      },
      {
        header: "Tested Time",
        accessorKey: "lastupdatedOn",
        accessorFn: (row) =>
          row?.lastUpdatedOn
            ? new Date(row.lastUpdatedOn.toLocaleString())
            : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "Entry Time",
        accessorKey: "createdAt",
        accessorFn: (row) =>
          row?.createdAt ? new Date(row.createdAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "Reported State",
        filterFn: "includesString",
        accessorFn: (row) => row?.reportedState || "",
      },
      // {
      //   header: "Slack Notifications",
      //   filterFn: "includesString",
      //   accessorFn: (row) =>
      //     row?.isNotified ? (
      //       <i className="bi bi-check-circle-fill text-success"></i>
      //     ) : (
      //       <i className="bi bi-x-circle-fill text-danger"></i>
      //     ),
      // },
    ],
    []
  );

  const table = useMaterialReactTable<ScrapingHealth>({
    columns,
    data: filteredData ?? [],
    enableRowNumbers: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      sorting: [
        {
          id: "Airline",
          desc: true,
        },
      ],
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <h6 className="mt-2">
          No of Airlines: {table.getFilteredRowModel().rows.length}{" "}
        </h6>

        <select
          value={selectedServerId}
          onChange={(e) => setSelectedServerId(e.target.value)}
          style={{ height: "36px", borderRadius: "4px" }}
        >
          <option value="">All Servers</option>
          {serverIdOptions.map((id) => (
            <option key={String(id)} value={String(id)}>
              {" "}
              {String(id)}
            </option>
          ))}
        </select>
      </Box>
    ),
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </>
  );
};
export default ScrapingHealthCheck;

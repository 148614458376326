import { useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { ScrapingTable } from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Col, Form, Row, Button, Container } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Scraping = () => {
  const [scraping, setScraping] = useState<ScrapingTable[]>([]); // Data from API
  const [showTable, setShowTable] = useState(false); // Controls table visibility
  const [awb, setAwb] = useState(""); // AWB input
  const [isValidAwb, setIsValidAwb] = useState(true); // Validity of AWB input
  const [loading, setLoading] = useState(false); // Loading state for submit button

  // AWB validation function
  const validateAwb = (awb: string) => /^[0-9]{3}-[0-9]{8}$/.test(awb);

  // Form submission handler
  const submitForm = async () => {
    if (!validateAwb(awb)) {
      setIsValidAwb(false);
      return;
    }
    setIsValidAwb(true);
    setLoading(true);

    try {
      const data = await WebhookUiService.getScraping(awb); // Fetch data for the specific AWB
      setScraping(data || []);
      setShowTable(true); // Show the table after successful data fetch
    } catch (error) {
      console.error("Error fetching AWB details:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const columns = useMemo<MRT_ColumnDef<ScrapingTable>[]>(() => [
    {
      header: "AWB Number",
      accessorKey: "awbNumber",
      Cell: ({ row }) => (
        <a
          href={`/scraping/${row.original.awbNumber}/${row.original.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary cursor-pointer"
        >
          {row.original.awbNumber}
        </a>
      ),
    },
    {
      header: "Before Tracking Status",
      accessorKey: "beforeTrackingStatus",
    },
    {
      header: "After Tracking Status",
      accessorKey: "afterTrackingStatus",
    },
    {
      header: "Entry Time",
      accessorKey: "createdAt",
      Cell: ({ cell }) => {
        const dateValue = cell.getValue<string>();
        return dateValue ? new Date(dateValue).toLocaleString() : "";
      },
    },
    {
      header: "Occurance",
      accessorKey: "count",
    },
    {
      header: "Time Difference",
      accessorKey: "timeDifference",
    },
    {
      header: "Webhook Sent",
      accessorKey: "webhookSent",
      Cell: ({ cell }) => (
        cell.getValue<boolean>() ? (
          <i className="bi bi-check-circle-fill text-success"></i>
        ) : (
          <i className="bi bi-x-circle-fill text-danger"></i>
        )
      ),
    },
    {
      header: "Webhook Status",
      accessorKey: "webhookStatus",
    },
    {
      header: "Webhook Time Difference",
      accessorKey: "webhookTimeDifference",
    },
  ], []);

  const table = useMaterialReactTable<ScrapingTable>({
    columns,
    data: scraping,
    // enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
    },
    state: {
      isLoading: loading,
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <Container className="py-6 px-4" style={{ minHeight: "100vh", maxWidth: "95%" }}>

          <Form>
            <Row className="my-2" style={{ alignItems: "flex-end" }}>
              <Col md={4}>
                <Form.Label htmlFor="awbInput">Enter AWB:</Form.Label>
                <Form.Control
                  type="text"
                  id="awbInput"
                  value={awb}
                  onChange={(e) => setAwb(e.target.value)}
                  isInvalid={!isValidAwb}
                />
                {!isValidAwb && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid AWB number (e.g., 176-12345678).
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col>
                <Button variant="primary" onClick={submitForm} disabled={loading}>
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
          {showTable && <MaterialReactTable table={table} />}

        </Container>
      </LocalizationProvider>
    </>
  );
};

export default Scraping;

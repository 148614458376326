import { Route } from "./Route";
import UserAirlineMapping from "../components/pages/tracking/UserAirlineMapping";

export class RoutesConfig {
  userAirlineMapping: Route = new Route("user_airline_mapping/:userId", "UserAirlineMapping");
  home: Route = new Route("/home", "Home");
  newBooking: Route = new Route("/booking/new", "NewBookingWorkflow");
  viewBooking: Route = new Route("/booking/:bookingId", "BookAirlineOrder");
  viewRatesShipper: Route = new Route(
    "/booking/check-rates",
    "ViewRatesShipper"
  );
  // login: Route = new Route("/login", "AppLogin");
  trackingLogin: Route = new Route("/tracking/login", "Login");
  trackingAdminLogin: Route = new Route("/tracking/admin/login", "Login");
  trackingRegister: Route = new Route("/tracking/register", "Login");
  trackingHome: Route = new Route("/tracking/search", "SearchAWB");
  trackingDashboard: Route = new Route("/demo", "TrackingDashboard");
  trackingAdminDashboard: Route = new Route(
    "/tracking/admin",
    "TrackingDashboard"
  );
  awbUsage: Route = new Route("/awb-usage", "AWBUsage");
  courierUsage: Route = new Route("/courier-usage", "CourierUsage");
  UnsupportedAirlines: Route = new Route(
    "/unsupport-airlines",
    "UnsupportedAirlines"
  );
  airlineInfo: Route = new Route("/airlineinfo", "AirlineInfo");
  trackingAdminDemo: Route = new Route("/tracking/demo", "TrackingDashboard");
  trackingUserList: Route = new Route("/userlist", "TrackingUserList");

  // createAccount: Route = new Route("/account/create/:token", "AccountCreate")
  createShipper: Route = new Route("/account/create/:token", "AccountCreate");
  generatePassword: Route = new Route(
    "/account-verification/:token",
    "GeneratePassword"
  );
  bookOrder: Route = new Route("/order/new/:bookingId", "BookAirlineOrder");
  dataSettings: Route = new Route("/data-settings", "DataSettings");
  viewOrder: Route = new Route("/order/:awb", "ViewOrder");
  updateOrder: Route = new Route("/order/update/:awb", "UpdateOrder");
  updateOrderStatus: Route = new Route(
    "/order/update/status/:awb",
    "UpdateOrderStatus"
  );
  updateOrderWeight: Route = new Route(
    "/order/update/weight/:awb",
    "UpdateOrderWeight"
  );
  updateOrderDocuemnt: Route = new Route(
    "/order/update/docs/:awb",
    "UpdateOrderDocument"
  );
  manageShipper: Route = new Route("/manage/shippers", "ManageShippers");
  addShipper: Route = new Route("/manage/add/shipper", "AddShipper");
  editShipper: Route = new Route("/manage/edit/shipper", "EditShipper");
  manageConsignee: Route = new Route("/manage/consignee", "ManageConsignee");
  addConsignee: Route = new Route("/manage/add/consignee", "AddConsignee");
  editConsignee: Route = new Route("/manage/edit/consignee", "EditConsignee");
  manageShipperCategory: Route = new Route(
    "/manage/shipper-category",
    "ManageShipperCategory"
  );
  addShipperCategory: Route = new Route(
    "/manage/add/shipper-category",
    "AddShipperCategory"
  );
  editShipperCategory: Route = new Route(
    "/manage/edit/shipper-category",
    "EditShipperCategory"
  );
  manageRates: Route = new Route("/manage/rates", "ManageRates");
  manageWallet: Route = new Route("/manage/wallet", "ManageWallet");
  accountViewWallet: Route = new Route("/account/wallet", "AccountViewWallet");
  flightScheduleDemo: Route = new Route("/flight/schedule", "ApiEval");

  //webhook data

  webhookData: Route = new Route("/webhook-data", "AwbTable");
  webhookRawData: Route = new Route("/rawdata/:id", "RawDataDisplay");
  userDemo: Route = new Route("/frontend-user", "UserDemo");

  //Client Ui
  clientLogin: Route = new Route("/login", "ClientLogin");
  clientOldLogin: Route = new Route("/client/login", "ClientLogin");
  clientDashBoard: Route = new Route("/dashboard", "ClientDashboard");
  availableAirline: Route = new Route("/availableairline", "AvailableAirline");
  addAwb: Route = new Route("/add-awb", "AddAwb");
  clientBilling: Route = new Route("/billing", "ClientBilling");
  clientProfile: Route = new Route("/profile", "ProfileContainer");
  userBilling: Route = new Route("/billing/:id", "UserBilling");
  forgotPassword: Route = new Route("/forgot-password", "ForgotPassword");
  log: Route = new Route("/log", "Log");
  etdeta: Route = new Route("/etd-eta", "Etdeta");
  scraping: Route = new Route("/scraping", "Scraping");
  dataCheck: Route = new Route("/scraping/:awbNumber/:id", "dataCheck");
  manualWebhookLog: Route = new Route(
    "/manual-webhook-log",
    "ManualWebhookLog"
  );
  scrapDeleteAddLog: Route = new Route("/scrap-delete-add-log", "DeleteAddLog");
  awbDetails: Route = new Route("/awbDetails", "awbDetails");
  awbEdit: Route = new Route("/awbedit/:awbNumber", "awbEdit");
  scrapingHealth: Route = new Route("/scrapingHealth", "ScrapingHealthCheck");
  scrapingTracking: Route = new Route("/scrapingTracking", "scrapingTracking");
  deleteAdd: Route = new Route("/deleteAdd", "DeleteAdd");
  scrapSetting: Route = new Route("/scrap-settings", "scrapSetting");
  dataSourceLog: Route = new Route("/data-source-changelog", "changelog");
  dataSourceSwapLog: Route = new Route("/data-source-swaplog", "swaplog");
  logView: Route = new Route("/log-view", "logview");
  dataCompare: Route = new Route("/data-compare", "DataCompare");
  dataView: Route = new Route("/data-compare/:awbNumber", "dataView");
  webhookTest: Route = new Route("/webhook-data/test-webhook", "webhookTest");
  webhookTestLog: Route = new Route("/webhook-data/test-webhook/log", "webhookTest");
  webhookView: Route = new Route("/webhook-data/view-webhook", "webhookView");
  combinePage: Route = new Route("/combine-page", "CombinePage");

  uploadHistory: Route = new Route("/upload-history", "UploadHistory");
}

import { useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { AWBDetails } from "../../../models/TrackingSummary";
import {
    MRT_ColumnFiltersState,
    MRT_SortingState,
    MRT_PaginationState,
    useMaterialReactTable,
    MRT_ColumnDef,
    MaterialReactTable,
} from "material-react-table";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";

const AWBDetailsView = () => {
    const [showTable, setShowTable] = useState(false);
    const [awb, setAwb] = useState("");
    const [isValidAwb, setIsValidAwb] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([
        { id: "createdDateTime", desc: true },
    ]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const { data: awbDetails, isLoading, isError, refetch } = useQuery(
        "awbDetails",
        () => WebhookUiService.getAWBDetails(awb),
        {
            enabled: false, // Do not call API initially
            refetchOnWindowFocus: false,
        }
    );

    const validateAwb = (awb: string) => /^[0-9]{3}-[0-9]{8}$/.test(awb);

    const submitForm = async () => {
        if (!validateAwb(awb)) {
            setIsValidAwb(false);
            return;
        }
        setIsValidAwb(true);
        setLoading(true);

        try {
            await refetch();
            setShowTable(true); // Show the table after successful fetch
        } catch (error) {
            console.error("Error fetching AWB details:", error);
        } finally {
            setLoading(false);
        }
    };

    const columns = useMemo<MRT_ColumnDef<AWBDetails>[]>(() => [
        {
            header: "AWB Number",
            filterFn: "includesString",
            accessorFn: (row) => row?.awbNumber || "",
        },
        {
            header: "Status",
            filterFn: "includesString",
            accessorFn: (row) => row?.trackingStatus || "",
        },
        {
            header: "Org",
            filterFn: "includesString",
            accessorFn: (row) => row?.origin || "",
        },
        {
            header: "Dest",
            filterFn: "includesString",
            accessorFn: (row) => row?.destination || "",
        },
        {
            header: "Wgt",
            filterFn: "includesString",
            accessorFn: (row) => row?.weight || "",
        },
        {
            header: "Pcs",
            filterFn: "includesString",
            accessorFn: (row) => row?.pieces || "",
        },
        {
            header: "Edit",
            Cell: ({ row }) => (
                <NavLink to={`/awbedit/${row.original.awbNumber}`}>Edit</NavLink>
            ),
        },
        {
            header: "Entry Time",
            accessorKey: "createdDateTime",
            accessorFn: (row) =>
                row?.createdDateTime
                    ? new Date(row.createdDateTime.toLocaleString())
                    : "",
            filterVariant: "date-range",
            Cell: ({ cell }) => {
                const dateValue = cell.getValue<string>();
                return dateValue ? new Date(dateValue).toLocaleString() : "";
            },
        },
    ], []);

    const table = useMaterialReactTable<AWBDetails>({
        columns,
        data: awbDetails ?? [],
        enableRowSelection: true,
        initialState: {
            showColumnFilters: false,
            density: "compact",
        },
        manualFiltering: false,
        manualPagination: false,
        manualSorting: false,
        muiToolbarAlertBannerProps: isError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container className="py-5">
                    <h1>AWB Edit</h1>
                    <Form>
                        <Row className="my-2" style={{ alignItems: "flex-end" }}>
                            <Col md={4}>
                                <Form.Label htmlFor="inputBox">Enter AWB:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="inputBox"
                                    name="inputBox"
                                    value={awb}
                                    onChange={(e) => setAwb(e.target.value)}
                                    isInvalid={!isValidAwb}
                                />
                                {!isValidAwb && (
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid AWB number (e.g., 176-12345678).
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col>
                                <Button
                                    variant="primary"
                                    onClick={submitForm}
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {showTable && <MaterialReactTable table={table} />}
                </Container>

            </LocalizationProvider>
        </>
    );
};

export default AWBDetailsView;

import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toastTypes } from "../../../utils/toastTypes";


type Props = {
  confirmText: string;
  header: string;
  show: boolean;
  trackingNumber?: string
  onSubmit: () => void;
  onHide: () => void;
};

function ConfirmActionPopup({
  confirmText,
  header,
  trackingNumber,
  onSubmit,
  onHide,
  show,
}: Props): JSX.Element {

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    console.log({ inputValue, trackingNumber });
    if (inputValue === trackingNumber) {
      onSubmit();
    } else {
      toastTypes.error("Tracking number does not match");
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: confirmText }} />
        <br />
        <strong>Enter Tracking Number to Confirm:</strong>
        <div className="mt-3 p-2">
          <input
            type="text"
            className="form-control"
            value={inputValue}
            placeholder="Tracking number"
            onChange={handleInputChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmActionPopup;
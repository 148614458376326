import { ChangeEvent, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toastTypes } from "../../../utils/toastTypes";

type Props = {
    addAwbToTracking: (awb: string) => Promise<boolean>;
};

export function SearchAwbFE(props: Props) {
    const [awbPrefix, setAwbPrefix] = useState<string>("");
    const [awbNum, setAwbNum] = useState<string>("");

    const awbNumInput = useRef<HTMLInputElement>(null);
    const isMobile = window.innerWidth < 768 ? true : false;

    const onAwbPrefixChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAwbPrefix(e.target.value);
        if (e.target.value.length === 3) {
            awbNumInput.current?.focus();
        }
    };

    const handleClick = async () => {

        if (await props.addAwbToTracking(awbPrefix + "-" + awbNum)) {
            setAwbPrefix("");
            setAwbNum("");
        }

    };
    return (
        <Container className={isMobile ? "mt-2 col-auto" : "mt-2 col-sm-4"}>
            {/* <Row className="my-2 mx-2">
                <Col className="px-0">
                    <h5 className="section-title">Add AWB to tracking</h5>
                </Col>
            </Row> */}
            <Row className="my-2 mx-2">
                <Col className="px-0">
                    <h6>Enter Airline AWB</h6>
                </Col>
            </Row>
            <Row className="my-2 mx-2 align-items-center">
                <Col className="px-0 col-2">
                    <Form.Control
                        maxLength={3}
                        value={awbPrefix}
                        minLength={3}
                        autoFocus
                        onChange={onAwbPrefixChange}
                        placeholder="Prefix"
                    />
                </Col>
                <Col className="px-1 col-auto">
                    <hr className="m-0" style={{ width: "20px", border: "1px solid #000" }} />
                </Col>
                <Col className="px-1 col-4">
                    <Form.Control
                        maxLength={8}
                        value={awbNum}
                        ref={awbNumInput}
                        onChange={(e) => setAwbNum(e.target.value)}
                        placeholder="AWB Number"
                    />
                </Col>
                <Col className="px-1 col-auto">
                    <Button onClick={handleClick}>Add</Button>
                </Col>
            </Row>
        </Container>
    );
}

import React from "react";

function TableHeader() {
  return (
    <thead className="text-start table-header">
      <tr>
        <th scope="col">#</th>
        <th scope="col">User Name</th>
        <th scope="col">Email</th>
        <th scope="col">
          Role
        </th>
        {/* <th scope="col" className="text-center">
          Tracking
        </th> */}
        {/* <th className="text-center">AWB Count</th>
        <th className="text-center">Monthly Limit</th> */}
        <th className="text-center">Action</th>
      </tr>
    </thead>
  );
}

export default TableHeader;

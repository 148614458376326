import axios from "axios";
import { AWBList, GenericResponse, unsuportedAirlines } from "../models/Common";
import {
  BillingCustomer,
  CreateInvoice,
  CreateReceipt,
  Invoice,
  InvoiceQuery,
  Receipt,
  UserBillingDetails,
} from "../models/Customer";
import envConfig from "../utils/envConfig";
const LUPA_BASE_URL = envConfig.url.API_URL;

export const http = axios.create({
  baseURL: LUPA_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

export async function getAllCustomers(): Promise<
  GenericResponse<BillingCustomer[]>
> {
  return http
    .get("/customer")
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getCustomerById(
  id: number
): Promise<GenericResponse<UserBillingDetails>> {
  return http
    .get(`/customer/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getInvoices(
  query: InvoiceQuery = {}
): Promise<GenericResponse<Invoice[]>> {
  return http
    .get(
      `/invoice?month=${query.month}&year=${query.year}&userId=${query.userId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function createInvoice(
  invoice: CreateInvoice
): Promise<GenericResponse<Invoice>> {
  return http
    .post("/invoice", invoice)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function createReceipt(
  receipt: CreateReceipt
): Promise<GenericResponse<Receipt>> {
  return http
    .post(`/receipt`, receipt)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getInvoiceById(
  id: string
): Promise<GenericResponse<Invoice>> {
  return http
    .get(`/invoice/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getAllReceipts(
  userId?: number
): Promise<GenericResponse<Receipt[]>> {
  return http
    .get(`/receipt?userId=${userId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function updateReceipt(
  receiptId: string,
  receipt: Partial<CreateReceipt>
): Promise<GenericResponse<Receipt>> {
  return http
    .put(`/receipt/${receiptId}`, receipt)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function getAWBList(): Promise<GenericResponse<AWBList[]>> {
  return http
    .get(`/awblist`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
export async function getCourierList(): Promise<any[]> {
  return http
    .get(`/courier`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
export async function getUnsupportedAirlines(): Promise<unsuportedAirlines[]> {
  return http
    .get(`/apiUser/unsuportedAirlines`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
export function getTrackingHistory(
  userId: number,
  month: string
): Promise<GenericResponse<any>> {
  console.log({ userId, month });
  return http
    .get(`/trackingHistory?userId=${userId}&month=${encodeURIComponent(month)}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
